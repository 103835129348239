


















































































































































































































































































































































import isEqual from "lodash/fp/isEqual";
import isEmpty from "lodash/fp/isEmpty";
import { endOfYear } from "date-fns";
import {
  computed,
  defineComponent,
  reactive,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "@vue/composition-api";
import moment from "moment";
import { userFacade } from "@/store/modules/user/user.facade";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { EStickerType, ERoutes } from "@/core/dataTypes/enum";
import { ValidityYear } from "@/core/dataTypes/types/sticker.interface";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import VehicleCard from "@/components/vehicle/VehicleCard/VehicleCard.vue";
import DatePicker from "@/components/forms/DatePicker/DatePicker.vue";
import AutocompleteInput from "@/components/forms/autocomplete/AutocompleteInput.vue";
import { transformTimeZone } from "@/core/transformers/sticker.transformer";
import DialogService from "@/core/services/dialog.service";
import CheckPlateNumberDialog from "@/components/dialog/CheckPlateNumberDialog.vue";
import ValidityOverlapDialog from "@/components/dialog/ValidityOverlapDialog.vue";
import { DialogResultType } from "@/core/dataTypes/types";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import router from "@/router";
import { tabFocusService } from "@/core/services/tabFocus.service";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import * as StickerService from "@/core/services/sticker.service";

export default defineComponent({
  name: "StickerChoice",
  setup(props, { emit }) {
    const sticker = reactive({
      stickerPurchasePeriod: 0,
      vehiclePriceCategory: "",
      stickerType: "",
      validityStart: "",
      validityYear: "",
      county: [],
    });
    const hasQuery = !isEmpty(router.app.$route.query);
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const isShowMessage = ref(true);
    const isLoading = ref(false);
    const hdVehicle = computed(stickerFacade.hdVehicle);
    const stickerTypeList = computed(stickerFacade.stickerTypeList);
    const stickerPurchasePeriodList = computed(
      stickerFacade.stickerPurchasePeriodList
    );
    const stickerTypeListLoading = computed(
      stickerFacade.stickerTypeListLoading
    );
    const countyList = computed(stickerFacade.countyList);
    const validityYear = computed(stickerFacade.validityYear);
    const preemption = computed(stickerFacade.preemption);
    const stickerValidityStart = computed(stickerFacade.stickerValidityStart);
    const stickerValidityEnd = computed(stickerFacade.stickerValidityEnd);
    const stickerPrice = computed(stickerFacade.stickerPrice);
    const productId = computed(stickerFacade.productId);
    const stickerOverlap = computed(stickerFacade.stickerOverlap);
    const endYearDate = ref(endOfYear(new Date()));

    const activeCartLoading = computed(stickerFacade.activeCartLoading);
    const checkStickerOverlapLoading = computed(
      stickerFacade.checkStickerOverlapLoading
    );
    const cartElementsLoading = computed(stickerFacade.cartElementsLoading);
    watch(
      () => hdVehicle.value,
      (newHdVehicle, oldHdVehicle) => {
        if (!isEqual(newHdVehicle, oldHdVehicle)) {
          initStickerData();
        }
      }
    );

    watch(
      () => stickerValidityStart.value,
      (newStickerValidityStart) => {
        if (newStickerValidityStart) {
          sticker.validityStart = newStickerValidityStart as unknown as string;
        }
      }
    );
    watch(
      () => sticker.validityStart,
      (validityStart) => {
        if (validityStart) {
          const validityStartYear = moment.parseZone(validityStart).year();
          const nowYear = moment.parseZone(new Date()).year();
          isShowMessage.value = !(validityStartYear > nowYear);
        }
      }
    );

    watch(
      () => validityYear.value,
      (newValidityYear) => {
        if (newValidityYear && newValidityYear.length) {
          const validityList = newValidityYear as ValidityYear[];
          const yearValidity = preemption.value
            ? validityList[validityList.length - 1].value
            : validityList[0].value;
          sticker.validityYear = yearValidity as unknown as string;
        }
      }
    );

    const initStickerData = () => {
      alertFacade.resetAlert();
      sticker.vehiclePriceCategory = hdVehicle.value
        ?.hdVehicleCategoryId as unknown as string;
      resetSticker();
      stickerFacade.resetStickerChoice();
      stickerFacade.getStickerTypeList(sticker.vehiclePriceCategory);
    };

    const resetSticker = () => {
      isLoading.value = false;
      sticker.stickerType = "";
      sticker.validityStart = "";
      sticker.validityYear = "";
      sticker.stickerPurchasePeriod = 0;
      sticker.county = [];
      stickerFacade.resetStickerPrice();
    };

    const onSelectStickerType = async () => {
      isLoading.value = true;
      sticker.stickerPurchasePeriod = 0;
      alertFacade.resetAlert();
      await stickerFacade.resetValidationYears();
      switch (sticker.stickerType) {
        case EStickerType.ONEDAY_N:
          try {
            await stickerFacade.postStickerValidityStart({
              stickerType: sticker.stickerType,
              vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
            });
            await stickerFacade.postStickerValidityEnd({
              validityStart: sticker.validityStart,
              stickerType: sticker.stickerType,
              vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
            });
            await stickerFacade.postStickerPrice({
              vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
              validityStart: sticker.validityStart,
              stickerType: sticker.stickerType,
            });
            isLoading.value = false;
          } catch (error) {
            isLoading.value = false;
            resetSticker();
          }
          break;
        case EStickerType._10DAYS_N:
        case EStickerType.MONTHLY_N:
          try {
            await stickerFacade.postStickerValidityStart({
              stickerType: sticker.stickerType,
              vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
            });
            await stickerFacade.postStickerPurchasePeriod({
              stickerType: sticker.stickerType,
              validityStart: sticker.validityStart,
            });
            sticker.stickerPurchasePeriod = 1;
            await stickerFacade.postStickerValidityEnd({
              stickerType: sticker.stickerType,
              vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
              validityStart: sticker.validityStart,
              stickerPurchasePeriod: Number(sticker.stickerPurchasePeriod),
            });

            await stickerFacade.postStickerPrice({
              vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
              validityStart: sticker.validityStart,
              stickerType: sticker.stickerType,
              stickerPurchasePeriod: Number(sticker.stickerPurchasePeriod),
            });
            isLoading.value = false;
          } catch (error) {
            isLoading.value = false;
            resetSticker();
          }
          break;

        case EStickerType.YEARLY_N:
        case EStickerType.YEARLY_C:
          sticker.county = [];
          sticker.validityYear = "";
          try {
            await stickerFacade.getPreemption(sticker.stickerType);
            isShowMessage.value = !preemption.value;
            if (sticker.stickerType === EStickerType.YEARLY_C) {
              await stickerFacade.resetStickerPrice();
              await stickerFacade.postCountyList({
                vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
                validityYear: sticker.validityYear,
              });
            } else {
              await stickerFacade.postStickerValidityStart({
                stickerType: sticker.stickerType,
                vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
                validityYear: sticker.validityYear,
              });
              await stickerFacade.postStickerValidityEnd({
                stickerType: sticker.stickerType,
                vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
                validityStart: sticker.validityStart,
                validityYear: sticker.validityYear,
              });
              await stickerFacade.postStickerPrice({
                vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
                validityStart: sticker.validityStart,
                stickerType: sticker.stickerType,
                validityYear: sticker.validityYear,
              });
            }
            isLoading.value = false;
          } catch (error) {
            isLoading.value = false;
            resetSticker();
          }
          break;
      }
    };

    const onSelectStickerPurchasePeriod = async () => {
      await stickerFacade.postStickerValidityEnd({
        stickerType: sticker.stickerType,
        vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
        validityStart: sticker.validityStart,
        stickerPurchasePeriod: Number(sticker.stickerPurchasePeriod),
      });
      await stickerFacade.postStickerPrice({
        vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
        validityStart: sticker.validityStart,
        stickerType: sticker.stickerType,
        stickerPurchasePeriod: Number(sticker.stickerPurchasePeriod),
      });
    };

    const onSelectStickerValidityStart = async () => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const currentPeriod = Number(sticker.stickerPurchasePeriod);
      const isOneDay = sticker.stickerType === EStickerType.ONEDAY_N;
      if (!isOneDay) {
        await stickerFacade.postStickerPurchasePeriod({
          stickerType: sticker.stickerType,
          validityStart: sticker.validityStart,
        });
      }
      const periodNumber = stickerPurchasePeriodList.value?.length as number;
      sticker.stickerPurchasePeriod = Number(
        periodNumber < currentPeriod ? 1 : currentPeriod
      );
      const postStickerValidityEndData: any = {
        stickerType: sticker.stickerType,
        vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
        validityStart: sticker.validityStart,
        stickerPurchasePeriod: Number(sticker.stickerPurchasePeriod),
      };
      if (isOneDay) {
        delete postStickerValidityEndData.stickerPurchasePeriod;
      }
      await stickerFacade.postStickerValidityEnd(postStickerValidityEndData);

      const postStickerPriceData: any = {
        vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
        validityStart: sticker.validityStart,
        stickerType: sticker.stickerType,
        stickerPurchasePeriod: Number(sticker.stickerPurchasePeriod),
      };
      if (isOneDay) {
        delete postStickerPriceData.stickerPurchasePeriod;
      }
      await stickerFacade.postStickerPrice(postStickerPriceData);
    };

    const onSelectCount = async () => {
      if (sticker.county.length) {
        await stickerFacade.postStickerValidityStart({
          stickerType: sticker.stickerType,
          vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
          validityYear: sticker.validityYear,
          countyNumberList: {
            countyNumber: sticker.county,
          },
        });
        await stickerFacade.postStickerValidityEnd({
          stickerType: sticker.stickerType,
          vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
          validityStart: sticker.validityStart,
          validityYear: sticker.validityYear,
          countyNumberList: {
            countyNumber: sticker.county,
          },
        });
        await stickerFacade.postStickerPrice({
          vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
          validityStart: sticker.validityStart,
          stickerType: sticker.stickerType,
          validityYear: sticker.validityYear,
          countyNumberList: {
            countyNumber: sticker.county,
          },
        });
      } else {
        await stickerFacade.resetValidationYears();
        await stickerFacade.resetStickerPrice();
      }
    };

    const onSelectValidityYear = async () => {
      try {
        await stickerFacade.postStickerValidityStart({
          vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
          stickerType: sticker.stickerType,
          validityYear: sticker.validityYear,
          ...(sticker.stickerType === EStickerType.YEARLY_C && {
            countyNumberList: {
              countyNumber: sticker.county,
            },
          }),
        });
        await stickerFacade.postStickerValidityEnd({
          vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
          stickerType: sticker.stickerType,
          validityStart: sticker.validityStart,
          validityYear: sticker.validityYear,
          ...(sticker.stickerType === EStickerType.YEARLY_C && {
            countyNumberList: {
              countyNumber: sticker.county,
            },
          }),
        });
        await stickerFacade.postStickerPrice({
          vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
          stickerType: sticker.stickerType,
          validityStart: sticker.validityStart,
          validityYear: sticker.validityYear,
          ...(sticker.stickerType === EStickerType.YEARLY_C && {
            countyNumberList: {
              countyNumber: sticker.county,
            },
          }),
        });
      } catch (error) {
        resetSticker();
      }
    };

    const onTriggerDate = (date: Date) => {
      tabFocusService.setFocus("toValidityStartDatePicker");
      sticker.validityStart = transformTimeZone(date);
      onSelectStickerValidityStart();
    };

    const addToCart = () => {
      if (props.isChooseNewVehicle) {
        openCheckPlateNumberDialog();
      } else {
        postCheckStickerOverlap();
      }
    };

    const postCheckStickerOverlap = async () => {
      await stickerFacade.postCheckStickerOverlap({
        vehicle: {
          licensePlateNumber: hdVehicle.value?.plateNumber,
          country: hdVehicle.value?.countryCode,
        },
        productIdListType: {
          productId: productId.value,
        },
        validityStart: sticker.validityStart as string,
        validityEnd: stickerValidityEnd?.value,
      });

      if (!stickerOverlap.value?.length) {
        await postCartElements();
      } else {
        openStickerOverlapDialog();
      }
    };

    const openCheckPlateNumberDialog = () => {
      DialogService.open({
        maxWidth: 424,
        persistent: true,
        component: CheckPlateNumberDialog,
      }).$on("dialogResult", (): void => {
        if (notLoggedInUser.value) {
          if (StickerService.getAdHocCartId()) {
            getAdHocValidityCheck();
          } else {
            postCartElements();
          }
        } else {
          postCheckStickerOverlap();
        }
      });
    };
    const getAdHocValidityCheck = () => {
      stickerFacade.getAdHocValidityCheck().then((isValid) => {
        if (isValid) {
          postCartElements();
        } else {
          resetSticker();
          emit("changeStep", 1);
        }
      });
    };

    const openStickerOverlapDialog = () => {
      DialogService.open({
        maxWidth: 424,
        persistent: true,
        customClass: "fullscreen-dialog-on-xs",
        componentProps: {
          overlaps: stickerOverlap.value,
        },
        component: ValidityOverlapDialog,
      }).$on("dialogResult", (result: DialogResultType): void => {
        if (result && result.payload === "ADD") {
          postCartElements();
        }
      });
    };

    const postCartElements = async () => {
      await stickerFacade.postCartElements({
        vehiclePriceCategory: hdVehicle.value?.hdVehicleCategoryId,
        stickerType: sticker.stickerType,
        validityStart: sticker.validityStart,
        vehicle: {
          licensePlateNumber: hdVehicle.value?.plateNumber,
          country: hdVehicle.value?.countryCode,
        },
        ...(sticker.stickerType === EStickerType.YEARLY_C && {
          countyNumberList: {
            countyNumber: sticker.county,
          },
        }),
        ...((sticker.stickerType === EStickerType.YEARLY_N ||
          sticker.stickerType === EStickerType.YEARLY_C) && {
          validityYear: sticker.validityYear,
        }),
        ...((sticker.stickerType === EStickerType._10DAYS_N ||
          sticker.stickerType === EStickerType.MONTHLY_N) && {
          stickerPurchasePeriod: Number(sticker.stickerPurchasePeriod),
        }),
      });
      await stickerFacade.getActiveCart();
      nextStep();
    };

    const nextStep = () => {
      router
        .push(
          getLanguageStaticRoute(
            `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_PAY}`
          )
        )
        .catch((err) => err);
    };
    const prevStep = () => {
      alertFacade.resetAlert();
      emit("changeStep", 1);
    };

    onMounted(() => {
      if (hasQuery && props.step === 2) {
        initStickerData();
        emit("deleteQueryParam");
      }
    });

    onUnmounted(() => {
      stickerFacade.resetHdVehicle();
    });

    return {
      // list,
      hdVehicle,
      stickerTypeList,
      stickerPurchasePeriodList,
      stickerTypeListLoading,
      sticker,
      EStickerType,
      stickerValidityStart,
      stickerValidityEnd,
      validityYear,
      preemption,
      countyList,
      isLoading,
      stickerPrice,
      endYearDate,
      productId,
      stickerOverlap,
      checkStickerOverlapLoading,
      cartElementsLoading,
      isShowMessage,
      onTriggerDate,
      nextStep,
      prevStep,
      onSelectStickerType,
      onSelectStickerPurchasePeriod,
      onSelectStickerValidityStart,
      onSelectValidityYear,
      onSelectCount,
      addToCart,
      activeCartLoading,
    };
  },
  props: {
    isChooseNewVehicle: Boolean,
    step: Number,
  },
  components: {
    CardSelector,
    VehicleCard,
    DatePicker,
    AutocompleteInput,
  },
});
